import React, { Fragment } from 'react';
import { useStaticQuery, graphql, Link } from 'gatsby';
import { Icon } from 'react-icons-kit';
import { chevronRight } from 'react-icons-kit/feather/chevronRight';
import Image from 'gatsby-image';
import Text from 'reusecore/src/elements/Text';
import Heading from 'reusecore/src/elements/Heading';
import GlideCarousel from 'common/src/components/GlideCarousel';
import GlideSlide from 'common/src/components/GlideCarousel/glideSlide';
import LeftBar from './leftBar';
import BannerWrapper, {
  ContentWrapper,
  TextArea,
  ImageArea,
  HighlightedText,
} from './bannerSection.style';

const BannerSection = () => {
  const glideOptions = {
    type: 'carousel',
    perView: 1,
    gap: 0,
  };

  const data = useStaticQuery(graphql`
    query {
      charityJson {
        bannerSlides {
          id
          thumb_url {
            childImageSharp {
              fluid(quality: 100) {
                ...GatsbyImageSharpFluid
              }
            }
          }
        }
      }
    }
  `);

  return (
    <BannerWrapper id="banner">
      <LeftBar text="SCROLL DOWN" offset={81} sectionId="#feature" />
      <ContentWrapper>
        <TextArea>
          <HighlightedText className="highlighted_text">
            <strong>RPA</strong> Robotik Process Automation...
            <Icon icon={chevronRight} />
          </HighlightedText>
          <Heading
            content="Otonom işgücü?"
          />
          <Heading
            as="h4"
            content="Basit yapay zeka ile çalışabilen otonom işgücü size karlılığın kapılarını açar."
          />
          <Text
            content="Yandaki resimde General motor firmasının Autocad yazılımının geliştirilmesinden önceki (1982) fabrika düzenini göstermektedir. Lütfen Ok-> ikonunu tıklayarak ikinci resmi inceleyiniz. Burada da  Tesla fabrikasını (2016) görmektesiniz. Bizde bu müthiş değişime ayak uydurmanıza katkı için otonom iş gücüne geçişi öneriyoruz. Otonom işgücü(RPA) şirketiniz içinde mevcut iş süreçlerine kısa sürede eklenerek işbaşı yapmaktadır. Size ölçülebilir, kayda değer bir iş gücü artışı sunmaktadır."
          />
          <Link className="learn__more-btn" to="#feature">
            <span className="hyphen" />
            <span className="btn_text">Biz size otonom işgücü (RPA) sağlıyoruz</span>
          </Link>
        </TextArea>
        <ImageArea>
          <GlideCarousel
            carouselSelector="charitySlide"
            options={glideOptions}
            nextButton={<span className="next_arrow" />}
            prevButton={<span className="prev_arrow" />}
          >
            <Fragment>
              {data.charityJson.bannerSlides.map(slide => (
                <GlideSlide key={slide.id}>
                  <Image
                    fluid={
                      (slide.thumb_url !== null) | undefined
                        ? slide.thumb_url.childImageSharp.fluid
                        : {}
                    }
                    alt={`Charity slide image ${slide.id}`}
                    className="slide_image"
                  />
                </GlideSlide>
              ))}
            </Fragment>
          </GlideCarousel>
        </ImageArea>
      </ContentWrapper>
    </BannerWrapper>
  );
};

export default BannerSection;
