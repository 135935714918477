import React from 'react';
import Heading from 'reusecore/src/elements/Heading';
import Text from 'reusecore/src/elements/Text';
import Image from 'reusecore/src/elements/Image';
import Container from 'common/src/components/UI/Container';
import SectionWrapper, {
  SectionHeader,
  ImageWrapper,
} from './mapSection.style';

import mapImage from 'common/src/assets/image/charity/map.png';

const MapSection = () => {
  return (
    <SectionWrapper  id="map">
      <Container width="1200px">
        <SectionHeader>
          <Heading content="Hangi tip datalarla otonom çalışabiliyoruz?" />
          <Text content="E-posta, excel, pdf, veritabanı, text, dosya sistemi, jpg/pdf gibi resim dosyaları, Uzak masa üstü, citrix data, chat verileri, chrome/explorer benzeri browser dataları, ses dosyaları, ERP/CRM, muhasebe  uygulamaları vb. datalarla hazırlayacagımız uygulama ile otonom iş gücüne dönüştürebiliyoruz.  Otonom iş gücü alanına aşagıda gruplandığı biçimde; buzulun üstündeki ve altındaki, gizli ve açık bütün datalar entegre kullanılabilmektedir." />
        </SectionHeader>
        <ImageWrapper>
          <Image src={mapImage} alt="Black data kullanımı yapılabilir." />
        </ImageWrapper>
      </Container>
    </SectionWrapper>
  );
};

export default MapSection;
