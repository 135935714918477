import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useStaticQuery, graphql } from 'gatsby';
import Box from 'reusecore/src/elements/Box';
import Text from 'reusecore/src/elements/Text';
import Image from 'reusecore/src/elements/Image';
import Container from 'common/src/components/UI/Container';
import InputGroup from 'common/src/components/InputGroup';
import RadioGroup from 'common/src/components/RadioGroup';
import SectionWrapper, {
  ContentArea,
  Heading,
  ButtonGroup,
  DonationForm,
  DonateButton,
} from './donateSection.style';

import heartImage from 'common/src/assets/image/charity/heart-alt.svg';

const DonateSection = ({ row, col }) => {
  const data = useStaticQuery(graphql`
    query {
      charityJson {
        paymentPolicy {
          id
          title
          text
          value
        }
        currencyOptions {
          id
          title
          value
        }
      }
    }
  `);

  const [state, setState] = useState({
    price: '',
    currency: 'usd',
    policy: 'oneTime',
  });

  const handleFormData = (value, name) => {
    setState({
      ...state,
      [name]: value,
    });
  };

  const handleDonation = e => {
    e.preventDefault();
    console.log('Donation form data: ', state);

    setState({
      ...state,
      price: '',
    });
  };

  return (
    <SectionWrapper id="donate">
      <Container width="1260px">
        <Box className="row" {...row}>
          <Box className="col" {...col}>
            <ContentArea>
              <Heading>
                Kullanıcı bazlı veya Robot bazlı servislerimiz vardır. Bu servisler istege bağlı olarak bulutta veya <span>on-premise</span> sizin kendi sistemlerinizde çalışabilmektedir.
              </Heading>
              <Text content="Detaylı bilgi ve POC çalışmaları için  info@bilgiciftligi.com  adresine e-posta gönderebilirsiniz." />

            </ContentArea>
          </Box>

          <Box className="col" {...col}>
            <DonationForm onSubmit={e => handleDonation(e)}>
              
              <RadioGroup
                name="radioGroup"
                value={state.policy}
                items={data.charityJson.paymentPolicy}
                onUpdate={value => handleFormData(value, 'policy')}
              />

            </DonationForm>
          </Box>
        </Box>
      </Container>
    </SectionWrapper>
  );
};

// DonateSection style props
DonateSection.propTypes = {
  row: PropTypes.object,
  col: PropTypes.object,
  textStyle: PropTypes.object,
};

// DonateSection default style
DonateSection.defaultProps = {
  // DonateSection row default style
  row: {
    flexBox: true,
    flexWrap: 'wrap',
    ml: '-15px',
    mr: '-15px',
  },
  // DonateSection col default style
  col: {
    width: ['100%', '50%', '50%'],
    pl: '15px',
    pr: '15px',
    mb: '30px',
  },
};

export default DonateSection;
